<template>
  <v-app>
    <v-container fluid>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <div :class="$style.headTitle">
            シフト希望登録申請システム
          </div>
          <div :class="$style.headSubTitle">
            {{this.$store.getters.getDeptCd}}：{{this.$store.getters.getDeptNm}}
          </div>
          <div :class="$style.title">
            パスワード変更
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div style="background-color: white; color: black; height: 70px; width: 100%; padding: 10px; font-size: 15px; text-align: left;">
            パスワードは半角英小文字大文字(a～z、A～Z)、数字(0～9)をそれぞれ1つ以上含む8文字以上16文字以下で設定してください。
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <v-alert
            v-model="alertValue"
            v-bind:type="alertType"
            dismissible
            dense
            outlined
          >
            {{ this.alertmessage }}
          </v-alert>
          <v-dialog v-model="confirmDialog" max-width="300">
            <v-card>
              <v-card-text>
                <div style="padding-top: 20px;">
                  パスワードを変更しますか？
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="regist">はい</v-btn>
                <v-btn @click="confirmDialog = false">いいえ</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div>
            <v-text-field
              v-model="oldPassword"
              label="現在のパスワード"
              v-bind:type="showPasswordOld ? 'text' : 'password'"
              @click:append="showPasswordOld = !showPasswordOld"
              v-bind:append-icon="showPasswordOld ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div>
            <v-text-field
              v-model="newPassword"
              label="新パスワード"
              v-bind:type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              counter
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div>
            <v-text-field
              v-model="newPasswordCfm"
              label="新パスワード確認"
              v-bind:type="showPasswordCfm ? 'text' : 'password'"
              @click:append="showPasswordCfm = !showPasswordCfm"
              v-bind:append-icon="showPasswordCfm ? 'mdi-eye' : 'mdi-eye-off'"
              outlined
              counter
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <div v-show="this.registBtn">
        <v-row dense
          class="text-center gradient"
          justify="center"
        >
          <v-col cols="12" md="8">
          <div>
            <v-btn block tile elevation="0" class="info" color="blue" height="35" @click="registConfirm()">変更</v-btn>
          </div>
          </v-col>
        </v-row>
      </div>
      <v-row dense
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
        <div>
          <v-btn block tile elevation="0" class="info" color="blue" height="35" @click="returnMenu()">戻る</v-btn>
        </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'LoginPassEdit',
  data: () => ({
    alertValue: false,
    alertType: 'success',
    alertmessage: '',
    confirmDialog: false,
    showPasswordOld: false,
    showPassword: false,
    showPasswordCfm: false,
    oldPassword: '',
    newPassword: '',
    newPasswordCfm: '',
    registBtn: true
  }),
  mounted () {
    if (this.$store.getters.getUserId === null) {
      this.$router.push({ name: 'LogIn' })
      return
    }
    if (typeof this.$store.getters.getMessage !== 'undefined' && this.$store.getters.getMessage !== '') {
      this.alertValue = true
      this.alertType = 'error'
      this.alertmessage = this.$store.getters.getMessage
      console.log('message:', this.$store.getters.getMessage)
    }
  },
  methods: {
    returnMenu () {
      this.alertValue = false
      // メニュー画面へ遷移
      if (this.$store.getters.getOriginalScreen !== null) {
        this.$router.push({ name: this.$store.getters.getOriginalScreen })
      } else {
        this.$router.push({ name: 'ShiftplanMenu' })
      }
    },
    registConfirm () {
      // 入力チェック
      this.alertValue = false

      if (this.oldPassword === '') {
        this.alertValue = true
        this.alertType = 'error'
        this.alertmessage = '現在のパスワードを入力してください。'
        return
      }
      if (this.newPassword === '') {
        this.alertValue = true
        this.alertType = 'error'
        this.alertmessage = '新パスワードを入力してください。'
        return
      }
      if (this.newPasswordCfm === '') {
        this.alertValue = true
        this.alertType = 'error'
        this.alertmessage = '新パスワード確認を入力してください。'
        return
      }
      if (this.oldPassword === this.newPassword) {
        this.alertValue = true
        this.alertType = 'error'
        this.alertmessage = '現在のパスワードと同じパスワードは設定できません。'
        return
      }
      if (this.newPassword !== this.newPasswordCfm) {
        this.alertValue = true
        this.alertType = 'error'
        this.alertmessage = '新パスワードと新パスワード確認が異なります。'
        return
      }
      // 半角英小文字大文字、数字をそれぞれ1つ以上含む8文字以上16文字以下
      const regex = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,16}$/
      if (!regex.test(this.newPassword)) {
        this.alertValue = true
        this.alertType = 'error'
        this.alertmessage = 'パスワードは半角英小文字大文字、数字をそれぞれ1つ以上含む8文字以上16文字以下で設定してください。'
        return
      }
      this.confirmDialog = true
    },
    regist () {
      this.confirmDialog = false
      // パスワード変更
      const params = {
        newPassword: this.newPassword,
        oldPassword: this.oldPassword,
        centerCd: this.$store.getters.getCenterCd,
        wfmUserId: this.$store.getters.getUserId
        // userCd: this.$store.getters.getUserCd
      }
      this.axios.post('https://039b1ygxn4.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/passwordchange', params)
        .then(response => {
          console.log('status:', response.status)
          console.log('message:', response.data)
          this.alertValue = true
          this.alertType = 'success'
          this.alertmessage = 'パスワードが変更されました。'
          // 遷移元画面をメニューに変更
          this.$store.dispatch('setOriginalScreen', 'ShiftplanMenu')
          //  エラーメッセージクリア
          this.$store.dispatch('setMessage', '')
          // 変更ボタン非表示
          this.registBtn = false
        }).catch(err => {
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          this.alertValue = true
          this.alertType = 'error'
          this.alertmessage = err.response.data
        })
    }
  }
}
</script>
<style module>
  .headTitle {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .headSubTitle {
    background-color: white;
    color: black;
    height: 30px;
    width: 100%;
    padding: 0px;
  }
  .title {
    background-color: darkblue;
    color: white;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .bodyTitle {
    background-color: gainsboro;
    color: black;
    height: 35px;
    width: 100%;
    padding: 6px;
  }
  .bodyLabel {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
  }
  .detailTitle {
    background-color:dodgerblue;
    color: white;
    text-align:center;
  }
</style>
