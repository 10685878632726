<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" color="primary">mdi-calendar</v-icon>
    </template>
    <v-date-picker
       v-model="picker"
       scrollable
       locale="jp-ja"
       :day-format="(date) => new Date(date).getDate()"
       :allowed-dates="allowedDate"
       @click="menu = false"
    />
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: new Date().toISOString().substr(0, 10)
    },
    lastShiftMonth: {
      type: String
    }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    picker: {
      get () {
        return this.value
      },
      set (val) {
        this.menu = false
        if (!val) return null
        const [year, month, day] = val.split('-')
        val = `${year}/${month}/${day}`
        this.$emit('input', val)
        this.$emit('parent-event')
      }
    }
  },
  methods: {
    allowedDate: function (val) {
      let minDay = new Date()
      let maxDay = new Date()
      // 今月1日
      minDay.setMonth(minDay.getMonth() - 2)
      minDay.setDate(1)
      minDay = new Date(minDay.getFullYear(), (minDay.getMonth()), minDay.getDate())
      // 引数で指定された月
      maxDay.setFullYear(Number(this.lastShiftMonth.slice(0, 4)))
      maxDay.setMonth(Number(this.lastShiftMonth.slice(4, 6)))
      maxDay.setDate(1)
      maxDay = new Date(maxDay.getFullYear(), (maxDay.getMonth()), maxDay.getDate())
      return minDay <= new Date(val) && new Date(val) < maxDay
    }
  }
}
</script>
<style>
.v-date-picker-table.v-date-picker-table--date
  > table
  > tbody
  tr
  td:nth-child(7)
  .v-btn__content {
  color: blue;
}

.v-date-picker-table.v-date-picker-table--date
  > table
  > tbody
  tr
  td:nth-child(1)
  .v-btn__content {
  color: red;
}
</style>
