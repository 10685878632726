<template>
  <v-app>
    <v-container fluid>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div :class="$style.headTitle">
            シフト希望登録申請システム
          </div>
          <div :class="$style.headSubTitle">
            {{this.$store.getters.getDeptCd}}：{{this.$store.getters.getDeptNm}}
          </div>
          <div :class="$style.title">
            休暇申請
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            休暇日
          </div>
        </v-col>
      </v-row>

      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <v-alert
            v-model="alertValue"
            v-bind:type="alertType"
            dismissible
            outlined
            dense
          >
            {{ alertMessage }}
          </v-alert>
       </v-col>
      </v-row>

      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          {{lastShiftMonth.slice(0, 4)}}年{{lastShiftMonth.slice(4, 6)}}月分まで申請できます
        </v-col>
      </v-row>

      <v-row justify="center" dense>
        <v-col cols="3">&nbsp;</v-col>
        <v-col cols="6">
          <v-text-field v-model="holiday" class="center-input" single-line readonly clearable>
            <template v-slot:append-outer>
              <date-picker :lastShiftMonth="lastShiftMonth" v-model="holiday" @parent-event="holidaySelect"/>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <div style="padding-top: 20px; white-space: pre-line">
            {{dayAttr}}
          </div>
        </v-col>
      </v-row>

      <v-dialog v-model="initDialog" max-width="300">
        <v-card>
          <v-card-text>
            <div style="padding-top: 20px; white-space: pre-line">
              {{initMessage}}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="initDialog = false">はい</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmDialog" max-width="300">
        <v-card>
          <v-card-text>
            <div style="padding-top: 20px; white-space: pre-line">
              {{confirmMessage}}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="commit">はい</v-btn>
            <v-btn @click="confirmDialog = false">いいえ</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmDialog2" max-width="300">
        <v-card>
          <v-card-text>
            <div style="padding-top: 20px; white-space: pre-line">
              {{confirmMessage2}}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="commit2">はい</v-btn>
            <v-btn @click="confirmDialog2 = false">いいえ</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8" mt="8" pd="8" pt="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            申請内容
          </div>
          <div>
            <div v-show="this.login_error != true" :class="$style.bodyLabel">
              <label :class="$style.selectboxSSS">
                <select v-model="selectedApplyCls">
                  <option disabled value="0">選択して下さい</option>
                  <option v-for="applyCls in optionApplyCls"
                          v-bind:value="applyCls.id"
                          v-bind:key="applyCls.id">
                          {{ applyCls.name }}
                  </option>
                </select>
              </label>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8" mt="8" pd="8" pt="8">
          <div v-show="this.selectedApplyCls == '4' || this.selectedApplyCls == '5' || this.selectedApplyCls == '7' || this.selectedApplyCls == '10'">
            <div v-show="this.login_error != true" :class="$style.bodyLabel">
                始業時刻
                <vue-timepicker
                     id="startTime"
                     name="startTime"
                     minute-interval="5"
                     hour-label="時"
                     minute-label="分"
                     input-width="100px"
                     v-model="startTime">
                </vue-timepicker>
            </div>
            &nbsp;
            <div v-show="this.login_error != true" :class="$style.bodyLabel">
              終業時刻
              <vue-timepicker
                   id="endTime"
                   name="endTime"
                   minute-interval="5"
                   hour-label="時"
                   minute-label="分"
                   input-width="100px"
                   v-model="endTime">
              </vue-timepicker>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            連絡事項
          </div>
          <v-textarea v-show="this.login_error != true"
              height="75px"
              outlined
              v-model="comment"
              placeholder="年休の理由は入力不要です">
            </v-textarea>
        </v-col>
      </v-row>
      <div v-show="this.holiday != '' && this.holiday.replace(/(\\|\/)/g,'') < nowDate">
        <v-row
          class="text-center gradient"
          justify="center"
        >
          <v-col cols="12" md="8">
            <div v-show="this.login_error != true" :class="$style.bodyTitle">
              事後申請理由
            </div>
            <v-textarea v-show="this.login_error != true"
                height="75px"
                outlined
                v-model="reason">
              </v-textarea>
          </v-col>
        </v-row>
      </div>

      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            勤怠状況
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            先月までの年休残：{{nenkyuZan}}日
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            申請した今月以降の年休：{{nenkyuApply}}日
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            昨日までの実働時間：{{totalTime}}時間
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            昨日までの時間外時間：{{overTime}}時間
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            昨日までの深夜時間：{{nightTime}}時間
          </div>
        </v-col>
      </v-row>

      <div v-show="this.applyBtn">
        <v-row
          class="text-center gradient"
          justify="center"
        >
          <v-col cols="12" md="8">
            <v-btn v-show="this.login_error != true" block tile elevation="0" class="info" color="blue" height="35" @click="registApply()">申請</v-btn>
          </v-col>
        </v-row>
      </div>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <v-btn block tile elevation="0" class="info" color="blue" height="35" @click="returnMenu()">戻る</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import DatePicker from '../../components/DatePicker'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'

export default {
  name: 'ShiftplanMenu',
  components: {
    DatePicker,
    'vue-timepicker': VueTimepicker
  },
  data () {
    return {
      initMessage: '',
      initDialog: false,
      confirmMessage: '',
      confirmDialog: false,
      confirmMessage2: '',
      confirmDialog2: false,
      holiday: '',
      startTime: '',
      endTime: '',
      workingShift: '',
      ampmShow: false,
      comment: '',
      reason: '',
      nowDate: '',
      login_error: false,
      input_error: false,
      error_message: '',
      alertValue: false,
      alertType: 'success',
      alertMessage: '',
      nenkyuZan: '',
      nenkyuApply: '',
      nenkyuApplyKonki: '',
      totalTime: '',
      overTime: '',
      nightTime: '',
      lastShiftMonth: '',
      nextKiYmd: '',
      applyBtn: true,
      publicPath: process.env.BASE_URL,
      selectedApplyCls: '0',
      applyClsList: [],
      optionApplyCls: [],
      dayAttr: ''
    }
  },
  mounted () {
    // スタッフ情報データ取得
    this.getStaffData()
    this.getNowDate()
  },
  methods: {
    // スタッフ情報、初期メッセージ、勤怠情報、確定シフト最終月検索
    getStaffData () {
      this.axios.get('https://0kw8km37f3.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/holidayApplyInit', {
        params: {
          centerCd: this.$store.getters.getCenterCd,
          deptCd: this.$store.getters.getDeptCd,
          userId: this.$store.getters.getUserId
        }
      })
        .then(response => {
          console.log('startTime:', response.data.startTime)
          console.log('endTime:', response.data.endTime)
          console.log('workingShift:', response.data.workingShift)
          console.log('message:', response.data.message)
          console.log('nenkyuZan:', response.data.nenkyuZan)
          console.log('nenkyuApply:', response.data.nenkyuApply)
          console.log('totalTime:', response.data.totalTime)
          console.log('overTime:', response.data.overTime)
          console.log('nightTime:', response.data.nightTime)
          console.log('lastShiftMonth:', response.data.lastShiftMonth)

          // 画面表示設定
          if (response.data.startTime === null) {
            this.startTime = ''
          } else {
            this.startTime = response.data.startTime
          }
          if (response.data.endTime === null) {
            this.endTime = ''
          } else {
            this.endTime = response.data.endTime
          }
          // 契約人時
          if (response.data.workingShift >= '8') {
            this.ampmShow = true
          }
          // メッセージ
          if (response.data.message === null) {
            this.initMessage = ''
            this.initDialog = false
          } else {
            this.initMessage = response.data.message
            this.initDialog = true
          }
          // 年休残日数
          this.nenkyuZan = response.data.nenkyuZan
          // 今月以降申請済の年休日数
          this.nenkyuApply = response.data.nenkyuApply
          // 今月以降申請済の年休日数（今期分）
          this.nenkyuApplyKonki = response.data.nenkyuApplyKonki
          // 就業時間
          this.totalTime = response.data.totalTime
          // 時間外時間
          this.overTime = response.data.overTime
          // 深夜時間
          this.nightTime = response.data.nightTime
          // 確定シフト最終月
          this.lastShiftMonth = response.data.lastShiftMonth
          // 次の期の開始日
          this.nextKiYmd = response.data.nextKiYmd
          // 年休申請スマホ選択マスタ
          this.applyClsList = response.data.applyClsList
          console.log('applyClsList.length:', this.applyClsList.length)
          let i
          // selectbox用の連想配列(optionApplyCls)に代入する
          for (i = 0; i < this.applyClsList.length; i++) {
            this.optionApplyCls.push({ id: this.applyClsList[i][0], name: this.applyClsList[i][1] })
          }
        }).catch(err => {
          if (typeof err.response === 'undefined') {
            this.$router.push({ name: 'LogIn' })
            return
          }
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          // 画面表示設定
          this.holiday = ''
          this.dayAttr = ''
          this.startTime = ''
          this.endTime = ''
          this.comment = ''
          this.reason = ''
          this.alertValue = true
          this.alertType = 'error'
          this.alertMessage = err.response.data
        })
    },
    // カレンダーマスタ情報取得
    holidaySelect () {
      this.axios.get('https://j70q989g06.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/shiftplanHolidayApplyGetHotei', {
        params: {
          centerCd: this.$store.getters.getCenterCd,
          deptCd: this.$store.getters.getDeptCd,
          userId: this.$store.getters.getUserId,
          wordDay: this.holiday.replace(/(\\|\/)/g, '')
        }
      })
        .then(response => {
          console.log('dayAttrCd:', response.data.dayAttrCd)
          console.log('dayAttrNm:', response.data.dayAttrNm)
          // 日々属性を表示
          if (response.data.dayAttrCd === '' || response.data.dayAttrCd === '0') {
            this.dayAttr = ''
          } else {
            this.dayAttr = response.data.dayAttrNm
          }
        }).catch(err => {
          if (typeof err.response === 'undefined') {
            this.$router.push({ name: 'LogIn' })
            return
          }
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          // 画面表示設定
          this.holiday = ''
          this.dayAttr = ''
          this.startTime = ''
          this.endTime = ''
          this.comment = ''
          this.reason = ''
          this.alertValue = true
          this.alertType = 'error'
          this.alertMessage = err.response.data
        })
    },
    // 本日の日付取得(yyyymmdd)
    getNowDate () {
      const dt = new Date()
      this.nowDate = dt.getFullYear() + '' + ('00' + (dt.getMonth() + 1)).slice(-2) + '' + ('00' + dt.getDate()).slice(-2)
    },
    // 申請ボタン押下
    registApply () {
      // 入力チェック
      this.checkInput()
      if (this.input_error === true) {
        this.alertValue = true
        this.alertType = 'error'
        this.alertMessage = this.error_message
        return
      }
      // 過去月チェック
      if (this.holiday.replace(/(\\|\/)/g, '').slice(0, 6) < this.nowDate.slice(0, 6)) {
        this.confirmMessage = '過去月の申請ですが、よろしいですか。'
      } else {
        this.confirmMessage = '申請します。よろしいですか。'
      }
      this.alertValue = false
      this.confirmDialog2 = false
      this.confirmDialog = true
    },
    // 入力チェック
    checkInput () {
      this.input_error = false
      // 必須入力
      if (this.holiday === '') {
        this.input_error = true
        this.error_message = '休暇日を入力してください。'
        return
      }
      if (this.selectedApplyCls === '0') {
        this.input_error = true
        this.error_message = '申請内容を選択してください。'
        return
      } else {
        if (this.selectedApplyCls === '4') {
          if (this.startTime === '') {
            this.input_error = true
            this.error_message = '遅参の場合は始業時間を入力して下さい。'
            return
          }
        }
        if (this.selectedApplyCls === '5') {
          if (this.endTime === '') {
            this.input_error = true
            this.error_message = '早退の場合は終業時間を入力して下さい。'
            return
          }
        }
        if (this.selectedApplyCls === '10') {
          if (this.startTime === '' || this.endTime === '') {
            this.input_error = true
            this.error_message = '時間代休の場合は始業・終業時間を入力して下さい。'
            return
          }
        }
      }
      // 事後申請理由
      if (this.holiday.replace(/(\\|\/)/g, '') < this.nowDate) {
        if (this.reason === '') {
          this.input_error = true
          this.error_message = '過去日の申請の場合は事後申請理由を入力して下さい。'
        }
      }
      // 年休残日数チェック
      if (this.selectedApplyCls === '1') {
        // 4月はチェックしない（３月末の年休残を参照するため）
        if (this.holiday.replace(/(\\|\/)/g, '').slice(4, 6) !== '04') {
          // 年休残≦今期申請日数
          if (Number(this.nenkyuZan) <= Number(this.nenkyuApplyKonki)) {
            // 今回の申請日が今期
            if (this.holiday.replace(/(\\|\/)/g, '') < this.nextKiYmd) {
              this.input_error = true
              this.error_message = '年休残が足りないので申請できません。'
            }
          }
        }
      }
    },
    // 登録実行
    commit () {
      this.confirmDialog = false
      this.confirmDialog2 = false
      this.applyRegist('Y')
    },
    // 登録実行（警告後）
    commit2 () {
      this.confirmDialog = false
      this.confirmDialog2 = false
      this.applyRegist('N')
    },
    applyRegist (checkYN) {
      // AM休の場合
      if (this.selectedApplyCls === '2') {
        this.startTime = '12:45'
      }
      // PM休の場合
      if (this.selectedApplyCls === '3') {
        this.endTime = '12:00'
      }
      this.axios.get('https://uzfo7e0ogg.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/holidayapplyregist', {
        params: {
          centerCd: this.$store.getters.getCenterCd,
          deptId: this.$store.getters.getDeptId,
          userId: this.$store.getters.getUserId,
          holiday: this.holiday.replace(/(\\|\/)/g, ''),
          applyCls: this.selectedApplyCls,
          startTime: this.startTime,
          endTime: this.endTime,
          comment: this.comment,
          reason: this.reason,
          check: checkYN
        }
      })
        .then(response => {
          console.log('status:', response.status)
          console.log('message:', response.data)
          this.alertValue = true
          this.alertType = 'success'
          this.alertMessage = '申請しました。'
          this.applyBtn = false
        }).catch(err => {
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          if (err.response.status === 300) {
            this.confirmMessage2 = err.response.data
            this.confirmDialog2 = true
          } else {
            this.alertValue = true
            this.alertType = 'error'
            this.alertMessage = err.response.data
          }
        })
    },
    returnMenu () {
      // メニュー画面へ遷移
      this.$router.push({ name: 'ShiftplanMenu' })
    }
  }
}
</script>

<style module>
  .headTitle {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .headSubTitle {
    background-color: white;
    color: black;
    height: 30px;
    width: 100%;
    padding: 0px;
  }
  .title {
    background-color: darkblue;
    color: white;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .bodyTitle {
    background-color: gainsboro;
    color: black;
    height: 35px;
    width: 100%;
    padding: 6px;
  }
  .bodyLabel {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
  }
  .detailTitle {
    background-color:dodgerblue;
    color: white;
    text-align:center;
  }
  .textare {
    height:60px;
    width:100%;
  }
  .center-input input {
    text-align: center;
  }
  .left-input input {
    text-align: left;
  }
  .right-input input {
    text-align: right;
  }
  .selectboxSSS {
      display: inline-flex;
      align-items: center;
      position: relative;
      text-align: center;
  }
  .selectboxSSS::after {
      position: absolute;
      right: 15px;
      width: 10px;
      height: 7px;
      background-color: #535353;
      clip-path: polygon(0 0, 100% 0, 50% 100%);
      content: '';
      pointer-events: none;
  }
  .selectboxSSS select {
      appearance: none;
      min-width: 120px;
      height: 2.6em;
      padding: .4em calc(.8em + 30px) .4em .8em;
      border: none;
      border-bottom: 2px solid #d0d0d0;
      background-color: #fff;
      color: #333333;
      font-size: 1em;
      cursor: pointer;
  }
  .selectboxSSS select:focus {
      outline: none;
  }
</style>
