<template>
  <v-app>
    <v-container fluid>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <div :class="$style.headTitle">
            シフト希望登録申請システム
          </div>
          <div :class="$style.headSubTitle">
            {{this.$store.getters.getDeptCd}}：{{this.$store.getters.getDeptNm}}
          </div>
          <div :class="$style.title">
            希望年休登録
          </div>
          <div v-show="this.login_error != true" style="background-color: white; color: black; height: 60px; width: 100%; padding: 10px; font-size: 15px;">
            希望年休を入力してください。
          </div>
          <v-alert
            v-model="alertValue"
            v-bind:type="alertType"
            dismissible
            outlined
            dense
          >
            {{ alertMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-sheet v-show="this.login_error != true" width="60vh" height="110vh" class="d-flex" >
          <v-calendar
            ref="calendar"
            :events="events"
            :event-color="getEventColor"
            :event-text-color ="getEventTextColor"
            locale="ja-jp"
            :day-format="dayFormat"
            :month-format="monthFormat"
            @change="getEvents"
            @click:event="changeEvent"
            @click:date ="clickDate"
            v-model="value"
          >
          </v-calendar>
        </v-sheet>
        <v-dialog v-model="confirmDialog" max-width="300">
          <v-card>
            <v-card-text>
              <div style="padding-top: 20px; white-space: pre-line">
                {{confirmMessage}}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="commit">はい</v-btn>
              <v-btn @click="confirmDialog = false">いいえ</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            連絡事項
          </div>
          <v-textarea v-show="this.login_error != true"
              height="75px"
              outlined
              v-model="staffComment">
            </v-textarea>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            年休希望状況
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            稼働日数：{{workDayCount}}日
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            年休日数：{{dayOffCount}}日
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            先月までの年休残：{{nenkyuZan}}日
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            昨日までの実働時間：{{totalTime}}時間
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            昨日までの時間外時間：{{overTime}}時間
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            昨日までの深夜時間：{{nightTime}}時間
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            現在の状態
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel" v-bind:style="{backgroundColor: bgcolor,color: fontColor}">
            {{inputStatusNm}}
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <v-btn v-show="this.login_error != true" block tile elevation="0" class="info" color="blue" height="35" :disabled="registDisabled" @click="shiftRegistPre()">登録</v-btn>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <v-btn v-show="false" block tile elevation="0" class="info" color="blue" height="35" :disabled="listDisabled" @click="shiftTmpRegistPre()">一時保存</v-btn>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <v-btn block tile elevation="0" class="info" color="blue" height="35" @click="goMenue()">戻る</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'ShiftplanRegist',
  data: () => ({
    events: [],
    staffComment: '',
    workDayCount: '',
    dayOffCount: '',
    totalTime: '',
    overTime: '',
    nightTime: '',
    inputStatusNm: '',
    value: '',
    shiftPlan: [],
    selectedEvent: {},
    selectedElement: null,
    largeClass: 'text-subtitle-2',
    bgcolor: '',
    fontColor: '',
    fontWidth: '100px',
    confirmDialog: false,
    updFlg: '',
    alertValue: false,
    alertType: 'success',
    alertMessage: '',
    confirmMessage: '',
    shiftType: {},
    shiftTypeCnv: {},
    shiftSelect: [],
    shiftBkcolor: [],
    shiftTxtcolor: [],
    shiftCommitDt: null,
    nenkyuZan: '',
    nenkyuOverFlg: '0'
  }),
  mounted () {
    // シフト計画データ取得
    this.getShiftData()
  },
  methods: {
    getShiftData () {
      this.axios.get('https://txnu061027.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/shiftplanregist', {
        params: {
          centerCd: this.$store.getters.getCenterCd,
          deptId: this.$store.getters.getDeptId,
          requestMonth: this.$store.getters.getSearchRequestMonth,
          userId: this.$store.getters.getUserId
        }
      })
        .then(response => {
          console.log('status:', response.status)
          console.log('message:', response.data)
          // 画面表示設定
          this.staffComment = response.data.staffComment
          this.workDayCount = response.data.workDayCount
          this.dayOffCount = response.data.dayOffRequestNum
          this.totalTime = response.data.totalTime
          this.overTime = response.data.overTime
          this.nightTime = response.data.nightTime
          this.inputStatusNm = response.data.inputStatusNm
          this.value = this.$store.getters.getSearchRequestMonth.substring(0, 4) + '-' + this.$store.getters.getSearchRequestMonth.substring(4, 6) + '-' + '01'
          this.shiftPlan = response.data.day
          this.shiftType = response.data.shiftType
          this.shiftTypeCnv = response.data.shiftTypeCnv
          this.shiftSelect = response.data.shiftSelect
          this.shiftBkcolor = response.data.shiftBkcolor
          this.shiftTxtcolor = response.data.shiftTxtcolor
          this.shiftCommitDt = response.data.shiftCommitDt
          this.nenkyuZan = response.data.nenkyuZan

          this.getEvents()
          this.getStyle()
        }).catch(err => {
          if (typeof err.response === 'undefined') {
            this.$router.push({ name: 'LogIn' })
            return
          }
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          this.alertValue = true
          this.alertType = 'error'
          this.alertMessage = err.response.data
        })
    },
    clickDate ({ date }) {
      this.value = this.$store.getters.getSearchRequestMonth.substring(0, 4) + '-' + this.$store.getters.getSearchRequestMonth.substring(4, 6) + '-' + '01'
      const day = Number(date.substring(8, 10)) - 1
      this.changeDate(this.events[day], date)
    },
    getEvents () {
      const events = []
      const CalDate = this.$store.getters.getSearchRequestMonth.substring(0, 4) + '-' + this.$store.getters.getSearchRequestMonth.substring(4, 6)
      for (let i = 0; i < this.shiftPlan.length; i++) {
        if (this.shiftPlan[i] !== 1) {
          // 日付設定
          let days = String(i + 1)
          if (days.length === 1) {
            days = '0' + days
          }
          const eventDate = new Date(CalDate + '-' + days)
          // 予定設定
          let shift = ''
          let color = ''
          let textColor = ''

          shift = this.shiftType[this.shiftPlan[i]]
          textColor = this.shiftTxtcolor[this.shiftPlan[i]]
          color = this.shiftBkcolor[this.shiftPlan[i]]

          // シフトがシフト選択に存在しないかつ非稼働日、対象外でない場合は”〇”扱いとする
          const nowIndex = this.shiftSelect.indexOf(shift)
          if (nowIndex === -1 && this.shiftPlan[i] !== '0' && this.shiftPlan[i] !== '1') {
            shift = this.shiftSelect[0]
            // シフトに対するコードを取得する
            const shiftCd = this.shiftTypeCnv[shift]
            // テキストカラーとバックカラーの再設定
            textColor = this.shiftTxtcolor[shiftCd]
            color = this.shiftBkcolor[shiftCd]
          }

          // シフト確定日が設定されている場合、シフト確定日以前のバックカラーを変更する
          if (this.shiftCommitDt !== '' && this.shiftCommitDt !== null) {
            const commitPlanDt = Number(this.shiftCommitDt.substring(6, 8))
            if (i + 1 <= commitPlanDt) {
              color = '#AFDFE4'
            }
          }

          events.push({
            name: shift,
            start: eventDate,
            end: eventDate,
            color: color,
            textColor: textColor,
            timed: false // 終日ならfalse
          })
        }
      }
      this.events = events
    },
    getStyle () {
      switch (this.inputStatusNm) {
        case '一時保存':
          this.bgcolor = 'gold'
          this.fontColor = 'navy'
          break
        case '未登録':
          this.bgcolor = 'lightpink'
          this.fontColor = 'crimson'
          break
        case '登録完了':
          this.bgcolor = 'paleturquoise'
          this.fontColor = 'navy'
          break
      }
    },
    getEventColor (event) {
      return event.color
    },
    getEventTextColor (event) {
      return event.textColor
    },
    changeEvent ({ event }) {
      // クリックした日付取得
      const clickDt = event.start.getDate()
      // シフト確定日が空でなく、クリックした日付<=シフト確定日の場合処理を抜ける
      if (this.shiftCommitDt !== '' && this.shiftCommitDt !== null) {
        const commitPlanDt = Number(this.shiftCommitDt.substring(6, 8))
        if (clickDt <= commitPlanDt) {
          return
        }
      }

      // シフト選択の最大インデックス取得
      const maxIndex = this.shiftSelect.length - 1
      // 現在のインデックス取得
      const nowIndex = this.shiftSelect.indexOf(event.name)

      // シフト選択リストの中にない場合は抜ける
      if (nowIndex === -1) {
        return
      }

      // 次のインデックス取得
      let nextIndex = 0
      if (nowIndex === maxIndex) {
        nextIndex = 0
      } else {
        nextIndex = nowIndex + 1
      }

      // シフトに対するコードを取得する
      const nextShiftCd = this.shiftTypeCnv[this.shiftSelect[nextIndex]]

      // イベント設定
      event.name = this.shiftType[nextShiftCd]
      event.color = this.shiftBkcolor[nextShiftCd]
      event.textColor = this.shiftTxtcolor[nextShiftCd]
    },
    changeDate (event, date) {
      if (this.$store.getters.getSearchRequestMonth.substring(4, 6) !== date.substring(5, 7)) {
        return
      }
      // クリックした日付取得
      const clickDt = event.start.getDate()
      // シフト確定日が空でなく、クリックした日付<=シフト確定日の場合処理を抜ける
      if (this.shiftCommitDt !== '' && this.shiftCommitDt !== null) {
        const commitPlanDt = Number(this.shiftCommitDt.substring(6, 8))
        if (clickDt <= commitPlanDt) {
          return
        }
      }
      // シフト選択の最大インデックス取得
      const maxIndex = this.shiftSelect.length - 1
      // 現在のインデックス取得
      const nowIndex = this.shiftSelect.indexOf(event.name)

      // シフト選択リストの中にない場合は抜ける
      if (nowIndex === -1) {
        return
      }

      // 次のインデックス取得
      let nextIndex = 0
      if (nowIndex === maxIndex) {
        nextIndex = 0
      } else {
        nextIndex = nowIndex + 1
      }

      // シフトに対するコードを取得する
      const nextShiftCd = this.shiftTypeCnv[this.shiftSelect[nextIndex]]

      // イベント設定
      const dayIndex = Number(date.substring(8, 10)) - 1
      this.events[dayIndex].name = this.shiftType[nextShiftCd]
      this.events[dayIndex].color = this.shiftBkcolor[nextShiftCd]
      this.events[dayIndex].textColor = this.shiftTxtcolor[nextShiftCd]
    },
    goMenue () {
      // メニュー画面へ遷移
      this.$router.push({ name: 'ShiftplanMenu' })
    },
    shiftRegistPre () {
      this.nenkyuOverChk()
      if ((this.nenkyuOverFlg === '1') && (this.$store.getters.getSearchRequestMonth.substring(4, 6) !== '04')) {
        this.confirmMessage = '年休希望日数が残年休日数を上回っています。\n' + '登録します。よろしいですか。'
      } else {
        this.confirmMessage = '登録します。よろしいですか。'
      }
      this.confirmDialog = true
      this.updFlg = '1' // 登録
    },
    shiftTmpRegistPre () {
      this.nenkyuOverChk()
      if ((this.nenkyuOverFlg === '1') && (this.$store.getters.getSearchRequestMonth.substring(4, 6) !== '04')) {
        this.confirmMessage = '年休希望日数が残年休日数を上回っています。\n' + '一時保存します。よろしいですか。'
      } else {
        this.confirmMessage = '一時保存します。よろしいですか。'
      }
      this.confirmDialog = true
      this.updFlg = '0' // 一時保存
    },
    nenkyuOverChk () {
      this.nenkyuOverFlg = '0'
      // 年休カウント
      let nenkyuCnt = 0
      for (let i = 0; i < this.events.length; i++) {
        if (this.events[i].name === '年') {
          nenkyuCnt = nenkyuCnt + 1
        }
      }
      // 年休チェック
      if (parseFloat(this.nenkyuZan) - nenkyuCnt < 0) {
        this.nenkyuOverFlg = '1'
      }
    },
    commit () {
      this.confirmDialog = false
      // シフトデータ登録
      this.shiftRegist()
    },
    shiftRegist () {
      // シフトデータ登録
      const shiftdata = []
      for (let i = 0; i < this.events.length; i++) {
        let shiftCd = ''
        let prop = ''
        prop = this.events[i].name
        shiftCd = this.shiftTypeCnv[prop]
        shiftdata.push(shiftCd)
      }
      // スタッフコメント編集
      let updComment = ''
      if (this.staffComment !== null) {
        updComment = this.staffComment
      }
      const params = {
        centerCd: this.$store.getters.getCenterCd,
        deptId: this.$store.getters.getDeptId,
        requestMonth: this.$store.getters.getSearchRequestMonth,
        userId: this.$store.getters.getUserId,
        updFlg: this.updFlg,
        day1: shiftdata[0],
        day2: shiftdata[1],
        day3: shiftdata[2],
        day4: shiftdata[3],
        day5: shiftdata[4],
        day6: shiftdata[5],
        day7: shiftdata[6],
        day8: shiftdata[7],
        day9: shiftdata[8],
        day10: shiftdata[9],
        day11: shiftdata[10],
        day12: shiftdata[11],
        day13: shiftdata[12],
        day14: shiftdata[13],
        day15: shiftdata[14],
        day16: shiftdata[15],
        day17: shiftdata[16],
        day18: shiftdata[17],
        day19: shiftdata[18],
        day20: shiftdata[19],
        day21: shiftdata[20],
        day22: shiftdata[21],
        day23: shiftdata[22],
        day24: shiftdata[23],
        day25: shiftdata[24],
        day26: shiftdata[25],
        day27: shiftdata[26],
        day28: shiftdata[27],
        day29: shiftdata[28],
        day30: shiftdata[29],
        day31: shiftdata[30],
        staffComment: updComment,
        userCd: this.$store.getters.getUserCd,
        nenkyuOverFlg: this.nenkyuOverFlg
      }
      this.axios.post('https://txnu061027.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/shiftplanregist', params)
        .then(response => {
          console.log('status:', response.status)
          console.log('message:', response.data)
          // 一時保存の場合
          if (this.updFlg === '0') {
            this.alertValue = true
            this.alertType = 'success'
            this.alertMessage = '一時保存しました。'
            this.getShiftData()
          // 登録の場合
          } else {
            // 希望年休登録完了画面へ遷移
            this.$router.push({ name: 'ShiftplanConfirm' })
          }
        }).catch(err => {
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          this.alertValue = true
          this.alertType = 'error'
          this.alertMessage = err.response.data
        })
    },
    dayFormat: function (date) {
      return new Date(date.date).getDate()
    },
    monthFormat: function (date) {
      return new Date(date.date).getMonth() + 1 + ' /'
    }
  }
}
</script>

<style module>
  .headTitle {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .headSubTitle {
    background-color: white;
    color: black;
    height: 30px;
    width: 100%;
    padding: 0px;
  }
  .title {
    background-color: darkblue;
    color: white;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .bodyTitle {
    background-color: gainsboro;
    color: black;
    height: 35px;
    width: 100%;
    padding: 6px;
  }
  .bodyLabel {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
  }
  .detailTitle {
    background-color:dodgerblue;
    color: white;
    text-align:center;
  }
    .textare {
    height:60px;
    width:100%;
  }
</style>
