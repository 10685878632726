import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import LogIn from '../views/Login.vue'
import LogInBA from '../views/LoginBA.vue'
import LogInBB from '../views/LoginBB.vue'
import LogInEB from '../views/LoginEB.vue'
import LogInHA from '../views/LoginHA.vue'
import LogInKA from '../views/LoginKA.vue'
import LogInNA from '../views/LoginNA.vue'
import LogInNB from '../views/LoginNB.vue'
import ShiftplanMenu from '../views/menu/Menu.vue'
import LoginPassEdit from '../views/password/LoginPassEdit.vue'
import ShiftplanRegist from '../views/shiftplan/Regist.vue'
import ShiftplanConfirm from '../views/shiftplan/Confirm.vue'
import ShiftplanList from '../views/shiftplan/List.vue'
import HolidayApply from '../views/holiday/HolidayApply.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'LogIn' }
  },
  {
    path: '/BA',
    redirect: { name: 'LogInBA' }
  },
  {
    path: '/BB',
    redirect: { name: 'LogInBB' }
  },
  {
    path: '/EB',
    redirect: { name: 'LogInEB' }
  },
  {
    path: '/HA',
    redirect: { name: 'LogInHA' }
  },
  {
    path: '/KA',
    redirect: { name: 'LogInKA' }
  },
  {
    path: '/NA',
    redirect: { name: 'LogInNA' }
  },
  {
    path: '/NB',
    redirect: { name: 'LogInNB' }
  },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/loginBA',
    name: 'LogInBA',
    component: LogInBA
  },
  {
    path: '/loginBB',
    name: 'LogInBB',
    component: LogInBB
  },
  {
    path: '/loginEB',
    name: 'LogInEB',
    component: LogInEB
  },
  {
    path: '/loginHA',
    name: 'LogInHA',
    component: LogInHA
  },
  {
    path: '/loginKA',
    name: 'LogInKA',
    component: LogInKA
  },
  {
    path: '/loginNA',
    name: 'LogInNA',
    component: LogInNA
  },
  {
    path: '/loginNB',
    name: 'LogInNB',
    component: LogInNB
  },
  {
    path: '/menu',
    name: 'ShiftplanMenu',
    component: ShiftplanMenu
  },
  {
    path: '/loginpassedit',
    name: 'LoginPassEdit',
    component: LoginPassEdit
  },
  {
    path: '/regist',
    name: 'ShiftplanRegist',
    component: ShiftplanRegist
  },
  {
    path: '/confirm',
    name: 'ShiftplanConfirm',
    component: ShiftplanConfirm
  },
  {
    path: '/list',
    name: 'ShiftplanList',
    component: ShiftplanList
  },
  {
    path: '/holiday',
    name: 'HolidayApply',
    component: HolidayApply
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
          message: true
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
