import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// export default new Vuex.Store({
const store = new Vuex.Store({
  state: {
    userId: null,
    nowPassword: null,
    deptCd: null,
    deptNm: null,
    centerCd: null,
    loginGr: null,
    deptId: null,
    requestMonth: null,
    searchRequestMonth: null,
    userCd: null,
    originalScreen: null,
    message: null
  },
  getters: {
    getUserId: function (state) {
      return state.userId
    },
    getNowPassword: function (state) {
      return state.nowPassword
    },
    getDeptCd: function (state) {
      return state.deptCd
    },
    getDeptNm: function (state) {
      return state.deptNm
    },
    getCenterCd: function (state) {
      return state.centerCd
    },
    getLoginGr: function (state) {
      return state.loginGr
    },
    getDeptId: function (state) {
      return state.deptId
    },
    getRequestMonth: function (state) {
      return state.requestMonth
    },
    getSearchRequestMonth: function (state) {
      return state.searchRequestMonth
    },
    getUserCd: function (state) {
      return state.userCd
    },
    getOriginalScreen: function (state) {
      return state.originalScreen
    },
    getMessage: function (state) {
      return state.message
    }
  },
  mutations: {
    setUserId: function (state, userId) {
      state.userId = userId
    },
    setNowPassword: function (state, password) {
      state.nowPassword = password
    },
    setDeptCd: function (state, deptCd) {
      state.deptCd = deptCd
    },
    setDeptNm: function (state, deptNm) {
      state.deptNm = deptNm
    },
    setCenterCd: function (state, centerCd) {
      state.centerCd = centerCd
    },
    setLoginGr: function (state, loginGr) {
      state.loginGr = loginGr
    },
    setDeptId: function (state, deptId) {
      state.deptId = deptId
    },
    setRequestMonth: function (state, requestMonth) {
      state.requestMonth = requestMonth
    },
    setSearchRequestMonth: function (state, searchRequestMonth) {
      state.searchRequestMonth = searchRequestMonth
    },
    setUserCd: function (state, userCd) {
      state.userCd = userCd
    },
    setOriginalScreen: function (state, originalScreen) {
      state.originalScreen = originalScreen
    },
    setMessage: function (state, message) {
      state.message = message
    },
    clearState: function (state) {
      state.userId = null
      state.nowPassword = null
      state.deptCd = null
      state.deptNm = null
      state.centerCd = null
      // state.loginGr = null
      state.deptId = null
      state.requestMonth = null
      state.searchRequestMonth = null
      state.userCd = null
      state.originalScreen = null
      state.message = null
    }
  },
  actions: {
    setUserId: function (context, userId) {
      context.commit('setUserId', userId)
    },
    setNowPassword: function (context, password) {
      context.commit('setNowPassword', password)
    },
    setDeptCd: function (context, deptCd) {
      context.commit('setDeptCd', deptCd)
    },
    setDeptNm: function (context, deptNm) {
      context.commit('setDeptNm', deptNm)
    },
    setCenterCd: function (context, centerCd) {
      context.commit('setCenterCd', centerCd)
    },
    setLoginGr: function (context, loginGr) {
      context.commit('setLoginGr', loginGr)
    },
    setDeptId: function (context, deptId) {
      context.commit('setDeptId', deptId)
    },
    setRequestMonth: function (context, requestMonth) {
      context.commit('setRequestMonth', requestMonth)
    },
    setSearchRequestMonth: function (context, searchRequestMonth) {
      context.commit('setSearchRequestMonth', searchRequestMonth)
    },
    setUserCd: function (context, userCd) {
      context.commit('setUserCd', userCd)
    },
    setOriginalScreen: function (context, originalScreen) {
      context.commit('setOriginalScreen', originalScreen)
    },
    setMessage: function (context, message) {
      context.commit('setMessage', message)
    },
    clearState: function (context) {
      context.commit('clearState')
    }
  },
  modules: {
  }
})

export default store
