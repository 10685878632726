<template>
  <v-app>
    <v-container fluid>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <div :class="$style.headTitle">
            シフト希望登録申請システム
          </div>
          <div :class="$style.headSubTitle">
            {{this.$store.getters.getDeptCd}}：{{this.$store.getters.getDeptNm}}
          </div>
          <div :class="$style.title">
            希望年休登録完了
          </div>
          <v-alert
            v-model="data_error"
            type="error"
            dense
            outlined
          >
            {{ this.error_message }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" style="background-color: white; color: black; height: 60px; width: 100%; padding: 10px; font-size: 15px;">
            <p v-show="this.shiftCommitPlanDt != ''">
              希望年休の登録が完了しました。<br>
              シフト確定予定日以降、確定シフトを確認してください。
            </p>
            <p v-show="this.shiftCommitPlanDt == ''">
              希望年休の登録が完了しました。<br>
              シフト確定後、確定シフトを確認してください。
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-show="this.shiftCommitPlanDt != ''"
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            <p>
              シフト確定予定日
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-show="this.shiftCommitPlanDt != ''"
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div v-show="this.data_error != true">
            <p :class="$style.bodyLabel">
              {{this.shiftCommitPlanDt}}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div>
              <v-btn block tile elevation="0" class="info" color="blue" height="35" @click="returnMenu()">メニュー</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'ShiftplanList',
  data () {
    return {
      shiftCommitPlanDt: '',
      data_error: false,
      error_message: ''
    }
  },
  mounted () {
    this.axios.get('https://c37llxo5mg.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/complete', {
      params: {
        centerCd: this.$store.getters.getCenterCd,
        deptId: this.$store.getters.getDeptId,
        requestMonth: this.$store.getters.getSearchRequestMonth,
        userId: this.$store.getters.getUserId
      }
    })
      .then(response => {
        console.log('status:', response.status)
        console.log('message:', response.data)
        // 画面表示
        if (response.data.shiftCommitPlanDt != null) {
          this.shiftCommitPlanDt = response.data.shiftCommitPlanDt.substring(4, 6) + '月' + response.data.shiftCommitPlanDt.substring(6, 8) + '日'
        }
      }).catch(err => {
        if (typeof err.response === 'undefined') {
          this.$router.push({ name: 'LogIn' })
          return
        }
        // エラーメッセージ表示
        console.log('errstatus:', err.response.status)
        console.log('message:', err.response.data)
        this.data_error = true
        this.error_message = err.response.data
      })
  },
  methods: {
    returnMenu () {
      // メニュー画面へ遷移
      this.$router.push({ name: 'ShiftplanMenu' })
    }
  }
}
</script>

<style module>
  .headTitle {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .headSubTitle {
    background-color: white;
    color: black;
    height: 30px;
    width: 100%;
    padding: 0px;
  }
  .title {
    background-color: darkblue;
    color: white;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .bodyTitle {
    background-color: gainsboro;
    color: black;
    height: 35px;
    width: 100%;
    padding: 6px;
  }
  .bodyLabel {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
  }
  .detailTitle {
    background-color:dodgerblue;
    color: white;
    text-align:center;
  }
</style>
