<template>
  <v-app>
    <v-container fluid>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <div :class="$style.headTitle">
            シフト希望登録申請システム
          </div>
          <div :class="$style.headSubTitle">
            {{this.$store.getters.getDeptCd}}：{{this.$store.getters.getDeptNm}}
          </div>
          <div :class="$style.title">
             {{this.$store.getters.getRequestMonth.substring(2,4)}}年{{this.$store.getters.getRequestMonth.substring(4,6)}}月度 希望シフト登録期間
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            対象年月
          </div>
        </v-col>
      </v-row>
      <v-row
        justify="center"
      >
        <v-col cols="1">
        </v-col>
        <v-col cols="3">
          <v-text-field
            class="center-input"
            hide-details
            outline
            dense
            v-model="requestYear"
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <div style="float:left; padding: 10px;">年</div>
        </v-col>
        <v-col cols="3">
          <v-select
            hide-details
            outline
            dense
            :items="items"
            v-model="requestMonth"
          ></v-select>
        </v-col>
        <v-col cols="1">
          <div style="float:left; padding: 10px;">月</div>
        </v-col>
        <v-col cols="3">
          <div>
            <div style="padding-left: 10px" height="35" @click="change()"><img :src="`${publicPath}koushin.png`" width="35px"></div>
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <v-alert
            v-model="login_error"
            type="error"
            dismissible
            dense
            outlined
          >
            {{ error_message }}
          </v-alert>
       </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            連絡事項
          </div>
          <v-textarea  v-show="this.login_error != true"
            height="200px"
            outlined
            readonly
            v-model="infoComment">
          </v-textarea>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8" style="padding-bottom: 0px;">
          <v-btn block tile elevation="0" color="#F08080" class="white--text" :disabled="registDisabled" @click="goShiftplanRegist()">希望年休登録</v-btn>
        </v-col>
        <v-col cols="12" md="8" style="padding-top: 0px;padding-bottom: 0px;">
          <v-btn block tile elevation="0" color="white" style="height: 50px;" :disabled="registDisabled" @click="goShiftplanRegist()"><img :src="`${publicPath}calendar.png`" width="60px"></v-btn>
        </v-col>
        <v-col cols="12" md="8" style="padding-top: 0px;">
          <v-btn block tile elevation="0" color="white" style="height: 14px;font-size: 11px;" :disabled="registDisabled" @click="goShiftplanRegist()">希望年休日を登録します</v-btn>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8" style="padding-bottom: 0px;">
          <v-btn block tile elevation="0" color="#9ACD32" class="white--text" :disabled="listDisabled" @click="goShiftplanList()">確定シフト確認</v-btn>
        </v-col>
        <v-col cols="12" md="8" style="padding-top: 0px;padding-bottom: 0px;">
          <v-btn block tile elevation="0" color="white" style="height: 50px;" :disabled="listDisabled" @click="goShiftplanList()"><img :src="`${publicPath}check.png`" width="40px"></v-btn>
        </v-col>
        <v-col cols="12" md="8" style="padding-top: 0px;">
          <v-btn block tile elevation="0" color="white" style="height: 14px;font-size: 11px;" :disabled="listDisabled" @click="goShiftplanList()">確定したシフトを確認します</v-btn>
        </v-col>

      </v-row>

      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8" style="padding-bottom: 0px;">
          <v-btn block tile elevation="0" color="#F3C201" class="white--text" :disabled="false" @click="goHolidayApply()">休暇申請</v-btn>
        </v-col>
        <v-col cols="12" md="8" style="padding-top: 0px;padding-bottom: 0px;">
          <v-btn block tile elevation="0" color="white" style="height: 50px;" :disabled="false" @click="goHolidayApply()"><img :src="`${publicPath}holiday.png`" width="40px"></v-btn>
        </v-col>
        <v-col cols="12" md="8" style="padding-top: 0px;">
          <v-btn block tile elevation="0" color="white" style="height: 14px;font-size: 11px;" :disabled="false" @click="goHolidayApply()">シフト確定後の休暇・時間変更を申請します</v-btn>
        </v-col>

      </v-row>

      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <v-btn block tile elevation="0" class="info" color="blue" height="35" @click="goShiftplanLogin()">ログアウト</v-btn>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div :class="$style.bodyLabel">
            パスワードの変更は<router-link to="/loginpassedit">こちら</router-link>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'ShiftplanMenu',
  data () {
    return {
      requestYear: this.$store.getters.getSearchRequestMonth.substring(0, 4),
      items: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      requestMonth: this.$store.getters.getSearchRequestMonth.substring(4, 6),
      infoComment: '',
      shiftDataStatus: '',
      login_error: false,
      error_message: '',
      registDisabled: false,
      listDisabled: false,
      publicPath: process.env.BASE_URL
    }
  },
  mounted () {
    // シフト計画データ取得
    this.getShiftData()
  },
  methods: {
    getShiftData () {
      // シフト計画検索
      this.axios.get('https://d2g8gzlht8.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/menu', {
        params: {
          centerCd: this.$store.getters.getCenterCd,
          deptId: this.$store.getters.getDeptId,
          requestMonth: this.$store.getters.getSearchRequestMonth,
          userId: this.$store.getters.getUserId
        }
      })
        .then(response => {
          console.log('status:', response.status)
          console.log('message:', response.data)
          // 画面表示設定
          if (response.data.infoComment === null) {
            this.infoComment = ''
          } else {
            this.infoComment = response.data.updDt + '更新\n' + response.data.infoComment
          }
          switch (response.data.shiftDataStatus) {
            case '2': // 依頼済
              this.registDisabled = false
              this.listDisabled = true
              break
            case '3': // 回答済
              this.registDisabled = false
              this.listDisabled = true
              break
            case '4': // 締切済
              this.registDisabled = true
              this.listDisabled = true
              break
            case '5': // 確定済
              this.registDisabled = true
              this.listDisabled = false
              break
          }
        }).catch(err => {
          if (typeof err.response === 'undefined') {
            // ログインGr取得
            var loginGr = this.$store.getters.getLoginGr
            this.$router.push({ name: 'LogIn' + loginGr })
            return
          }
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          // 画面表示設定
          this.login_error = true
          this.error_message = err.response.data
          this.infoComment = ''
          this.registDisabled = true
          this.listDisabled = true
        })
    },
    change () {
      // 入力チェック
      this.login_error = false
      // 必須入力
      if (this.requestYear === '') {
        console.log('errstatus:', 400)
        console.log('message:', '対象年を入力してください。')
        this.login_error = true
        this.error_message = '対象年を入力してください。'
        return
      }
      // 数値4桁
      const regex = /^[0-9]{4}$/
      if (!regex.test(this.requestYear)) {
        console.log('errstatus:', 400)
        console.log('message:', '対象年は数値4桁で入力してください。')
        this.login_error = true
        this.error_message = '対象年は数値4桁で入力してください。'
        return
      }

      // 入力された対象年月をvuex情報に保存
      this.$store.dispatch('setSearchRequestMonth', this.requestYear + this.requestMonth + '01') // 検索用対象年月
      this.getShiftData()
    },
    goShiftplanRegist () {
      // 希望年休登録画面へ遷移
      this.$router.push({ name: 'ShiftplanRegist' })
    },
    goShiftplanList () {
      // 確定シフト確認画面へ遷移
      this.$router.push({ name: 'ShiftplanList' })
    },
    goHolidayApply () {
      // 休暇申請画面へ遷移
      this.$router.push({ name: 'HolidayApply' })
    },
    goShiftplanLogin () {
      // ログインGr取得
      var loginGr = this.$store.getters.getLoginGr
      // vuex情報などの初期化
      this.$store.dispatch('clearState')
      // ログイン画面へ遷移
      this.$router.push({ name: 'LogIn' + loginGr })
    }
  }
}
</script>

<style module>
  .headTitle {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .headSubTitle {
    background-color: white;
    color: black;
    height: 30px;
    width: 100%;
    padding: 0px;
  }
  .title {
    background-color: darkblue;
    color: white;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .bodyTitle {
    background-color: gainsboro;
    color: black;
    height: 35px;
    width: 100%;
    padding: 6px;
  }
  .bodyLabel {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
  }
  .detailTitle {
    background-color:dodgerblue;
    color: white;
    text-align:center;
  }
  .textare {
    height:60px;
    width:100%;
  }
  .center-input input {
    text-align: center;
  }
  .left-input input {
    text-align: left;
  }
  .right-input input {
    text-align: right;
  }
</style>
