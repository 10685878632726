<template>
  <v-app>
    <v-container fluid>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <div :class="$style.headTitle">
            シフト希望登録申請システム
          </div>
          <div :class="$style.headSubTitle">
            {{this.$store.getters.getDeptCd}}：{{this.$store.getters.getDeptNm}}
          </div>
          <div :class="$style.title">
            確定シフト確認
          </div>
          <v-alert
            v-model="login_error"
            type="error"
            outlined
            dense
          >
            {{ error_message }}
          </v-alert>
          <div v-show="this.login_error != true" style="background-color: white; color: black; height: 60px; width: 100%; padding: 10px; font-size: 15px;">
              以下のシフトとなりました。<br>
              変更がある場合、リーダーへ申請してください。
          </div>
          <div v-show="this.login_error != true" style="background-color: white; color: blue; height: 30px; width: 100%; padding: 0px; font-size: 15px;">
              {{this.updDt}}&nbsp;&nbsp;更新
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            勤務時間
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            {{this.workHour}}
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            シフト詳細
          </div>
        </v-col>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-sheet v-show="this.login_error != true" width="60vh" height="85vh" class="d-flex" >
          <v-calendar
            ref="calendar"
            :events="events"
            :event-color="getEventColor"
            :event-text-color ="getEventTextColor"
            locale="ja-jp"
            :day-format="dayFormat"
            :month-format="monthFormat"
            @change="getEvents"
            @click:event="changeEvent"
            @click:date ="clickDate"
            v-model="value"
          >
          </v-calendar>
        </v-sheet>
        <v-dialog v-model="assignDialog" max-width="300">
          <v-card>
            <v-card-text>
              <div style="padding-top: 20px; white-space: pre-line">
                {{assignInfo}}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn @click="assignDialog = false">閉じる</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row
        class="text-center gradient"
        justify="center"
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            連絡事項
          </div>
          <v-textarea v-show="this.login_error != true"
              height="75px"
              readonly
              outlined
              v-model="staffComment">
            </v-textarea>
        </v-col>
      </v-row>

      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <div v-show="this.login_error != true" :class="$style.bodyTitle">
            勤怠状況
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            稼働日数：{{workDayCount}}日
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            年休日数：{{dayOffCount}}日
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            先月までの年休残：{{nenkyuZan}}日
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            昨日までの実働時間：{{totalTime}}時間
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            昨日までの時間外時間：{{overTime}}時間
          </div>
          <div v-show="this.login_error != true" :class="$style.bodyLabel">
            昨日までの深夜時間：{{nightTime}}時間
          </div>
        </v-col>
      </v-row>

      <v-row
        class="text-center gradient"
        justify="center"
        dense
      >
        <v-col cols="12" md="8">
          <v-btn block tile elevation="0" class="info" color="blue" height="35" @click="returnMenu()">戻る</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: 'ShiftplanList',
  data: () => ({
    events: [],
    staffComment: '',
    value: '',
    shiftPlan: [],
    selectedEvent: {},
    assignDialog: false,
    assignInfo: '',
    shiftType: {},
    shiftBkcolor: {},
    shiftTxtcolor: {},
    login_error: false,
    error_message: '',
    workHour: '',
    updDt: '',
    assignInfoDate: '',
    workDayCount: '',
    dayOffCount: '',
    nenkyuZan: '',
    totalTime: '',
    overTime: '',
    nightTime: ''
  }),
  mounted () {
    // シフト計画データ取得
    this.getShiftData()
  },
  methods: {
    getShiftData () {
      this.axios.get('https://xd0mtsid1i.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/list', {
        params: {
          centerCd: this.$store.getters.getCenterCd,
          deptId: this.$store.getters.getDeptId,
          requestMonth: this.$store.getters.getSearchRequestMonth,
          userId: this.$store.getters.getUserId
        }
      })
        .then(response => {
          console.log('status:', response.status)
          console.log('message:', response.data)
          // 画面表示設定
          this.staffComment = response.data.staffComment
          this.value = this.$store.getters.getSearchRequestMonth.substring(0, 4) + '-' + this.$store.getters.getSearchRequestMonth.substring(4, 6) + '-' + '01'
          this.shiftPlan = response.data.day
          this.shiftType = response.data.shiftType
          this.shiftBkcolor = response.data.shiftBkcolor
          this.shiftTxtcolor = response.data.shiftTxtcolor
          this.workHour = response.data.workHour
          this.updDt = response.data.updDt

          this.workDayCount = response.data.workDayCount
          this.dayOffCount = response.data.dayOffCount
          this.nenkyuZan = response.data.nenkyuZan
          this.totalTime = response.data.totalTime
          this.overTime = response.data.overTime
          this.nightTime = response.data.nightTime

          this.getEvents()
        }).catch(err => {
          if (typeof err.response === 'undefined') {
            this.$router.push({ name: 'LogIn' })
            return
          }
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          this.login_error = 'error'
          this.error_message = err.response.data
        })
    },
    clickDate ({ date }) {
      this.assignInfo = ''
      this.value = this.$store.getters.getSearchRequestMonth.substring(0, 4) + '-' + this.$store.getters.getSearchRequestMonth.substring(4, 6) + '-' + '01'
      const day = Number(date.substring(8, 10)) - 1
      this.changeDate(this.events[day], date)
    },
    getEvents () {
      const events = []
      const CalDate = this.$store.getters.getSearchRequestMonth.substring(0, 4) + '-' + this.$store.getters.getSearchRequestMonth.substring(4, 6)
      for (let i = 0; i < this.shiftPlan.length; i++) {
        if (this.shiftPlan[i] !== 1) {
          // 日付設定
          let days = String(i + 1)
          if (days.length === 1) {
            days = '0' + days
          }
          const eventDate = new Date(CalDate + '-' + days)
          // 予定設定
          let shift = ''
          let color = ''
          let textColor = ''

          shift = this.shiftType[this.shiftPlan[i]]
          // textColor = 'black'
          textColor = this.shiftTxtcolor[this.shiftPlan[i]]
          color = this.shiftBkcolor[this.shiftPlan[i]]

          events.push({
            name: shift,
            start: eventDate,
            end: eventDate,
            color: color,
            textColor: textColor,
            timed: false // 終日ならfalse
          })
        }
      }
      this.events = events
    },
    getEventColor (event) {
      return event.color
    },
    getEventTextColor (event) {
      return event.textColor
    },
    changeEvent ({ event }) {
      this.assignInfo = ''
      // クリックした日付取得
      let clickDt = String(event.start.getDate())
      // 一桁の場合前ゼロ付与
      if (clickDt.length === 1) {
        clickDt = '0' + clickDt
      }
      // 日別割付情報取得年月日生成（yyyymmdd形式）
      this.assignInfoDate = this.$store.getters.getSearchRequestMonth.substring(0, 4) + this.$store.getters.getSearchRequestMonth.substring(4, 6) + clickDt
      this.getAssignInfo()
      this.assignDialog = true
    },
    changeDate (event, date) {
      if (this.$store.getters.getSearchRequestMonth.substring(4, 6) !== date.substring(5, 7)) {
        return
      }
      // クリックした日付取得
      let clickDt = String(event.start.getDate())
      // 一桁の場合前ゼロ付与
      if (clickDt.length === 1) {
        clickDt = '0' + clickDt
      }
      // 日別割付情報取得年月日生成（yyyymmdd形式）
      this.assignInfoDate = this.$store.getters.getSearchRequestMonth.substring(0, 4) + this.$store.getters.getSearchRequestMonth.substring(4, 6) + clickDt
      this.getAssignInfo()
      this.assignDialog = true
    },
    getAssignInfo () {
      // 日別割付情報取得
      this.axios.get('https://e8usmtlxyf.execute-api.ap-northeast-1.amazonaws.com/shiftplan_pro/list', {
        params: {
          centerCd: this.$store.getters.getCenterCd,
          requestDay: this.assignInfoDate,
          userId: this.$store.getters.getUserId
        }
      })
        .then(response => {
          console.log('status:', response.status)
          console.log('message:', response.data)
          // 画面表示設定
          this.assignInfo = response.data.assignInfo
        }).catch(err => {
          if (typeof err.response === 'undefined') {
            this.$router.push({ name: 'LogIn' })
            return
          }
          // エラーメッセージ表示
          console.log('errstatus:', err.response.status)
          console.log('message:', err.response.data)
          this.login_error = 'error'
          this.error_message = err.response.data
        })
    },
    returnMenu () {
      // メニュー画面へ遷移
      this.$router.push({ name: 'ShiftplanMenu' })
    },
    dayFormat: function (date) {
      return new Date(date.date).getDate()
    },
    monthFormat: function (date) {
      return new Date(date.date).getMonth() + 1 + ' /'
    }
  }
}
</script>

<style module>
  .headTitle {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .headSubTitle {
    background-color: white;
    color: black;
    height: 30px;
    width: 100%;
    padding: 0px;
  }
  .title {
    background-color: darkblue;
    color: white;
    height:35px;
    width: 100%;
    padding: 6px;
    font-size: 16px;
  }
  .bodyTitle {
    background-color: gainsboro;
    color: black;
    height: 35px;
    width: 100%;
    padding: 6px;
  }
  .bodyLabel {
    background-color: white;
    color: black;
    height:35px;
    width: 100%;
    padding: 6px;
  }
  .detailTitle {
    background-color:dodgerblue;
    color: white;
    text-align:center;
  }
    .textare {
    height:60px;
    width:100%;
  }
</style>
